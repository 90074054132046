<template>
	<div>

		<!-- Payments Table -->
		<a-row :gutter="24" type="flex">

			<!-- Payments Table Column -->
			<a-col :span="24" class="mb-24" v-if="payments.length > 0">

				<!-- Payments Table Column -->
				<CardProjectTable2
					:data="payments"
					:columns="dataColumns"
				></CardProjectTable2>
				<!-- / Payments Table Column -->

			</a-col>
			<!-- / Payments Table Column -->

		</a-row>
		<!-- / Payments Table -->

	</div>
</template>

<script>

	// Bar chart for "Active Users" card.
	import CardBarChart from '../components/Cards/CardBarChart' ;

	// Line chart for "Sales Overview" card.
	import CardLineChart from '../components/Cards/CardLineChart' ;

	// Counter Widgets
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;

	// "Projects" table component.
	import CardProjectTable from '../components/Cards/CardProjectTable' ;

	// Order History card component.
	import CardOrderHistory from '../components/Cards/CardOrderHistory' ;

	// Information card 1.
	import CardInfo from '../components/Cards/CardInfo' ;

	// Information card 2.
	import CardInfo2 from '../components/Cards/CardInfo2' ;

	import CardProjectTable2 from '../components/Cards/CardProjectTable2' ;

	//Import UserService
	import UserService from '../services/UserService' 

	const dataColumns = []
	const agentColumns = [
		{
			title: 'MERCHANT ID',
			dataIndex: 'merchantId',
			key: 'merchantId',
			class: 'font-regular text-muted',
		},
		{
			title: 'DBA NAME',
			dataIndex: 'dbaName',
			key: 'dbaName',
			class: 'font-regular text-muted',
		},
		{
			title: 'ADV ID',
			dataIndex: 'advId',
			key: 'advId',
			class: 'font-regular text-muted',
		},
		{
			title: 'OPEN DATE',
			dataIndex: 'openDate',
			key: 'openDate',
			class: 'font-regular text-muted',
		},
		{
			title: 'ADV AMOUNT',
			dataIndex: 'fundedAmount',
			key: 'fundedAmount',
			class: 'font-regular text-muted',
		},
		{
			title: 'FEE',
			dataIndex: 'feeAmount',
			key: 'feeAmount',
			align: 'right',
			class: 'font-regular text-muted',
		},
		{
			title: 'REMIT %',
			dataIndex: 'remitPercent',
			key: 'remitPercent',
			align: 'right',
			class: 'font-regular text-muted',
		},
		{
			title: 'ADV BALANCE',
			dataIndex: 'advBalance',
			key: 'advBalance',
			align: 'right',
			class: 'font-regular text-muted',
		},
		{
			title: 'LAST PAYMENT',
			dataIndex: 'lastPaymentDate',
			key: 'lastPaymentDate',
			align: 'right',
			class: 'font-regular text-muted',
		},
		
	];

	const merchantColumns = [
		{
			title: 'POST DATE',
			dataIndex: 'openDate',
			key: 'openDate',
			class: 'font-semibold text-muted',
		},
		{
			title: 'ENTRY TYPE',
			dataIndex: 'entryType',
			key: 'entryType',
			class: 'font-semibold text-muted',
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			class: 'font-semibold text-muted',
		},
		{
			title: 'ADVANCE BALANCE',
			dataIndex: 'advBalance',
			key: 'advBalance',
			align: 'right',
			class: 'font-semibold text-muted',
		},
	];

	export default ({
		props: {
			payments: [],
			userData: {},
		},
		computed: {
			currentUser() {
				return this.$store.state.auth.user
			},
			dataColumns() {
				return this.userData.userType == "Agent" ? agentColumns : merchantColumns
			}
		},
		setup () {
			return { UserService }
		},
		components: {
			CardBarChart,
			CardLineChart,
			WidgetCounter,
			CardProjectTable,
			CardOrderHistory,
			CardInfo,
			CardInfo2,
			CardProjectTable2,
		},
	})

</script>